import * as monaco from "monaco-editor";

export const editorInsertContent = (editor: monaco.editor.IStandaloneCodeEditor, content: string) => {
  const p = editor.getPosition();
  editor.executeEdits("", [
    {
      range: new monaco.Range(p.lineNumber, p.column, p.lineNumber, p.column),
      text: content,
    },
  ]);
};
